import { SiteSettings } from "@health/queries";
import { OrderTimeoutsFormInputs } from "./types";

export const convertSettingsToBeInSeconds = (
  {
    orderAcceptanceTimeout,
    orderErxHubApprovalTimeout,
    orderErxHubApprovalResubmitTimeout,
    orderDispensingTimeout,
    orderDeliveryTimeout,
  }: OrderTimeoutsFormInputs = {} as OrderTimeoutsFormInputs
) => {
  return {
    orderDeliveryTimeout: 60 * Number(orderDeliveryTimeout),
    orderAcceptanceTimeout: 60 * Number(orderAcceptanceTimeout),
    orderDispensingTimeout: 60 * Number(orderDispensingTimeout),
    orderErxHubApprovalTimeout: 60 * Number(orderErxHubApprovalTimeout),
    orderErxHubApprovalResubmitTimeout: 60 * Number(orderErxHubApprovalResubmitTimeout),
  };
};
export const convertSettingsToBeInMinutes = (settings: SiteSettings) => {
  if (!settings) return settings;
  const {
    orderAcceptanceTimeout,
    orderErxHubApprovalTimeout,
    orderDispensingTimeout,
    orderErxHubApprovalResubmitTimeout,
    orderDeliveryTimeout,
  } = settings;
  return {
    orderDeliveryTimeout: Number(orderDeliveryTimeout) / 60,
    orderAcceptanceTimeout: Number(orderAcceptanceTimeout) / 60,
    orderDispensingTimeout: Number(orderDispensingTimeout) / 60,
    orderErxHubApprovalTimeout: Number(orderErxHubApprovalTimeout) / 60,
    orderErxHubApprovalResubmitTimeout: Number(orderErxHubApprovalResubmitTimeout) / 60,
  };
};
