import { getRequiredValidation } from "@health/common";
import { useTranslation } from "@health/i18n";
import { LocationPicker } from "@health/maps";
import { CitiesAutocomplete } from "@health/smart-ui";
import { FormCard, FormHelperText, Grid, Typography } from "@health/ui";
import { CustomTextField } from "pages/ProvidersManagement/components/Form/components/CustomTextField.components";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { formGirdBreakPoints, formGirdSpacing } from "shared/constants";
import { LocationProps } from "../types";
import { locationFields } from "./locationUtils";

const LocationCard: FC<LocationProps> = props => {
  const { t } = useTranslation("Admin");
  const { isLoading } = props;
  const {
    control,
    getValues,
    reset,

    formState: { errors: fromErrors, dirtyFields },
  } = useFormContext();
  return (
    <FormCard title={t("Address And Location")} loading={isLoading} doYouHaveData={true}>
      <Grid container spacing={formGirdSpacing}>
        {locationFields(t)?.map(item => (
          <Grid item {...formGirdBreakPoints} key={item.name}>
            <CustomTextField
              InputLabelProps={{ shrink: !!getValues()?.addressInput?.[item.value] || dirtyFields?.addressInput?.[item.value] }}
              name={item.name}
              label={item.label}
              pattern={item.pattern}
              errorsInput={fromErrors}
            />
          </Grid>
        ))}
        <Grid item {...formGirdBreakPoints}>
          <CitiesAutocomplete
            rules={{ required: getRequiredValidation(t, true) }}
            errors={fromErrors}
            control={control}
            cityItem={null}
            name='addressInput.city'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle1' gutterBottom>
            {t("Please Click on Map to detect your Coordinates")}
          </Typography>
          <Controller
            name='addressInput.coordinates'
            rules={{ required: getRequiredValidation(t, false) }}
            render={({ field }) => (
              <>
                {fromErrors?.addressInput?.coordinates?.message && (
                  <FormHelperText error>
                    {fromErrors?.addressInput?.coordinates?.message}, {t("Please Click on Map to detect your Coordinates")}
                  </FormHelperText>
                )}
                <LocationPicker
                  hasSearch
                  hasLocationLngLat
                  defaultCenter={{ lat: 24.750643, lng: 46.707766 }}
                  location={field.value}
                  // eslint-disable-next-line react/jsx-handler-names
                  onLocationChange={location => {
                    field.onChange(location.coordinates);
                    reset({
                      ...getValues(),
                      addressInput: { ...getValues().addressInput, area: location?.name, streetAddress1: location?.streetAddress1 },
                    });
                  }}
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
export default LocationCard;
