import { i18n, useTranslation } from "@health/i18n";
import { ProcessingStatus } from "@health/queries";
import { Box, Button, CircularProgress, CustomDialog, darkBlue, Divider, ExcelIcon, green, Grid, Typography } from "@health/ui";
import moment from "moment";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { getCodeSystemProcessingStatusColor, getCodeSystemProcessingStatusText } from "../medlistUtils";
import { VersionRowProps } from "./versionRow.types";

const VersionRow: FC<VersionRowProps> = props => {
  const { t } = useTranslation("Admin");
  const params = useParams();
  const [isActivateOpen, setIsActivateOpen] = useState(false);
  const { version, isLoading, latestApprovedVersionNumber, onVersionOpenClick, onVersionActiveChange, onDownload } = props || {};
  const { id, isActive, version: versionId, codeSystemHistory, processingStatus } = version || {};
  const updatedCodeSystem = codeSystemHistory?.[codeSystemHistory?.length - 1];
  const uploadDate = codeSystemHistory?.[0]?.updateDate
    ? moment(codeSystemHistory?.[0]?.updateDate).locale(i18n.language).format("DD MMMM YYYY")
    : "";
  const updateDate = updatedCodeSystem?.updateDate
    ? moment(updatedCodeSystem?.updateDate).locale(i18n.language).format("DD MMMM YYYY")
    : "";

  const activeColor = isActive ? green : darkBlue;

  const handleVersionOpenClick = () => {
    onVersionOpenClick && onVersionOpenClick(id!);
  };

  const handleDownloadClick = () => {
    onDownload && onDownload(id);
  };

  const handleToggleActivate = () => {
    setIsActivateOpen(!isActivateOpen);
  };

  const handleSwitchChange = event => {
    const value = event.target.checked;
    onVersionActiveChange && onVersionActiveChange(id, value);
    setIsActivateOpen(false);
  };
  return (
    <>
      <Grid container direction={"row"} alignItems='center'>
        <Grid item container flex={1}>
          <Box
            display={"flex"}
            width={40}
            height={40}
            bgcolor={activeColor}
            borderRadius={40}
            justifyContent='center'
            alignItems='center'
            marginRight={1}
          >
            <Typography color='white' fontSize={18} fontWeight={"bold"}>
              {versionId}
            </Typography>
          </Box>
          <Grid item>
            <Typography color={activeColor} fontWeight={"bold"} fontSize={14} paddingTop={isActive ? "" : "10px"}>
              {t("Version")}
              {versionId}
            </Typography>
            {isActive && (
              <Typography color={activeColor} fontSize={14}>
                {t("Current Version")}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item flex={1} container direction={"column"} alignItems={"center"}>
          <Typography color={getCodeSystemProcessingStatusColor(processingStatus)} fontSize={14}>
            {getCodeSystemProcessingStatusText(t, processingStatus as ProcessingStatus)}
          </Typography>
        </Grid>

        <Grid item container flex={1} alignItems={"center"}>
          <Grid item>
            <Typography color='primary' fontSize={14}>
              {uploadDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container flex={1} alignItems={"center"}>
          <Grid item>
            <Typography color='primary' fontSize={14}>
              {updateDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container flex={1} alignItems={"center"}>
          <Button onClick={handleDownloadClick} variant='text' startIcon={<ExcelIcon />}>
            {t("Download")}
          </Button>
        </Grid>

        <Grid item flex={1} alignItems={"center"}>
          {processingStatus == ProcessingStatus.Approved && (
            <CustomDialog
              maxWidth='xs'
              scrollType='body'
              open={isActivateOpen}
              onCloseModal={handleToggleActivate}
              button={
                <Button
                  color={"success"}
                  disabled={!!isActive || isLoading || latestApprovedVersionNumber !== versionId}
                  onClick={handleToggleActivate}
                  startIcon={isLoading && <CircularProgress size={15} />}
                >
                  {!isActive ? t("Publish") : t("Activated")}
                </Button>
              }
              footerComponent={
                <>
                  <Button color={"success"} onClick={handleSwitchChange}>
                    {t("Publish")}
                  </Button>
                  <Button color='error' size='medium' onClick={handleToggleActivate}>
                    {t("Cancel")}
                  </Button>
                </>
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={20} marginBottom='16px' color={darkBlue}>
                  {t("Are you sure you want to publish this version?")}
                </Typography>

                <Typography>{t("If you publish this version, all items in the list will be updated.")}</Typography>
              </Box>
            </CustomDialog>
          )}
        </Grid>
        <Grid item>
          <Box width={100}>{params?.id != id && <Button onClick={handleVersionOpenClick}>{t("Open")}</Button>}</Box>
        </Grid>
      </Grid>
      <Box marginTop={2} marginBottom={2}>
        <Divider
          sx={{
            borderBottomWidth: isActive ? "medium" : "light",
          }}
        />
      </Box>
    </>
  );
};

export default VersionRow;
