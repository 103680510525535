import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { EntryContainer } from "../../EntryContainer.component";
import { EntryLayout } from "../../EntryLayout.component";
import { RegisterForm } from "../../Form/RegisterForm";
import { AccountRegisterPageProps } from "./types";

const AccountRegister: FC<AccountRegisterPageProps> = ({ redirect: handleStep }) => {
  const { t } = useTranslation();
  return (
    <EntryLayout>
      <EntryContainer title={t("Yusur Signup")}>
        <RegisterForm onChangeStep={handleStep} />
      </EntryContainer>
    </EntryLayout>
  );
};
export default AccountRegister;
