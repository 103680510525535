import { createStyles } from "@mui/styles";
import { cyan, darkRed, deepViolet, green, orange, white } from "../../customization/colors";

export const dropdownStyles = () =>
  createStyles({
    paper: {
      width: 150,
      color: "#fff",
      borderRadius: 10,
    },
    list: {
      borderRadius: "0px 0px 10px 10px",
    },
    button: {
      width: 150,
      height: 45,
      fontSize: 13,
      borderRadius: 10,
      justifyContent: "space-between",

      "&.Mui-disabled": {
        color: white,
      },
    },
    buttonLabel: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontFamily: "Airbnb Cereal App,Book",
      fontSize: 14,
    },
    buttonWithOpen: {
      borderRadius: "10px 10px 0px 0px",
    },
    menu: {
      padding: "0px 2px",
      paddingBottom: 8,
    },
    menuError: {
      background: darkRed,
    },
    menuPrimary: {
      background: deepViolet,
    },
    menuSuccess: {
      background: green,
    },
    menuInfo: { background: cyan },
    menuWarning: { background: orange },
  });
