/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderProviderLocationMutationVariables = Types.Exact<{
  cityId: Types.Scalars['String'];
  longitude: Types.Scalars['Float'];
  latitude: Types.Scalars['Float'];
  id: Types.Scalars['ID'];
  nationalId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type OrderProviderLocationMutation = { __typename?: 'Mutation', orderProvideLocation?: { __typename?: 'OrderProvideLocation', order?: { __typename?: 'Order', status: Types.OrderStatus } | null, orderErrors: Array<{ __typename?: 'OrderError', field?: string | null, message?: string | null, code: Types.OrderErrorCode, branch?: string | null, orderLine?: string | null }> } | null };


export const OrderProviderLocationDocument = gql`
    mutation OrderProviderLocation($cityId: String!, $longitude: Float!, $latitude: Float!, $id: ID!, $nationalId: String) {
  orderProvideLocation(
    cityId: $cityId
    longitude: $longitude
    latitude: $latitude
    id: $id
    nationalId: $nationalId
  ) {
    order {
      status
    }
    orderErrors {
      field
      message
      code
      branch
      orderLine
    }
  }
}
    `;
export type OrderProviderLocationMutationFn = Apollo.MutationFunction<OrderProviderLocationMutation, OrderProviderLocationMutationVariables>;

/**
 * __useOrderProviderLocationMutation__
 *
 * To run a mutation, you first call `useOrderProviderLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderProviderLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderProviderLocationMutation, { data, loading, error }] = useOrderProviderLocationMutation({
 *   variables: {
 *      cityId: // value for 'cityId'
 *      longitude: // value for 'longitude'
 *      latitude: // value for 'latitude'
 *      id: // value for 'id'
 *      nationalId: // value for 'nationalId'
 *   },
 * });
 */
export function useOrderProviderLocationMutation(baseOptions?: Apollo.MutationHookOptions<OrderProviderLocationMutation, OrderProviderLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderProviderLocationMutation, OrderProviderLocationMutationVariables>(OrderProviderLocationDocument, options);
      }
export type OrderProviderLocationMutationHookResult = ReturnType<typeof useOrderProviderLocationMutation>;
export type OrderProviderLocationMutationResult = Apollo.MutationResult<OrderProviderLocationMutation>;
export type OrderProviderLocationMutationOptions = Apollo.BaseMutationOptions<OrderProviderLocationMutation, OrderProviderLocationMutationVariables>;