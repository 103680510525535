/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrescriptionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PrescriptionFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  filterEarlyRefillRequests?: Types.InputMaybe<Types.EarlyRefillRequestFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  branchDivision: Types.Scalars['String'];
}>;


export type PrescriptionsQuery = { __typename?: 'Query', me?: { __typename?: 'User', prescriptions?: { __typename?: 'PrescriptionCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'PrescriptionCountableEdge', node: { __typename?: 'Prescription', id: string, created: any, patientIdentifier?: string | null, referenceNumber: string, isFreeDelivery?: boolean | null, dispenseCount: number, lastDispenseDate?: any | null, remainingDaysToNextRefill?: number | null, dispenseStatus: Types.PrescriptionDispenseStatus, patient?: { __typename?: 'Patient', memberId?: string | null, firstName?: string | null, lastName?: string | null, modified: any } | null, medications?: Array<{ __typename?: 'Medication', id: string, display?: string | null, refills: number, dispenseCount: number, instructions: string, arabicInstructions: string, duration: string, quantity: number, remainingDaysToNextRefill?: number | null, tradeDrugs?: Array<{ __typename?: 'TradeDrug', code: string, dictionaryURL?: string | null, smallIconURL?: string | null } | null> | null } | null> | null, earlyRefillRequests?: { __typename?: 'EarlyRefillRequestCountableConnection', edges: Array<{ __typename?: 'EarlyRefillRequestCountableEdge', node: { __typename?: 'EarlyRefillRequest', id: string, status: Types.EarlyRefillRequestStatus, reason: { __typename?: 'EarlyRefillReason', reason: string } } }> } | null } }> } | null } | null };


export const PrescriptionsDocument = gql`
    query prescriptions($filter: PrescriptionFilterInput, $first: Int, $filterEarlyRefillRequests: EarlyRefillRequestFilterInput, $after: String, $before: String, $last: Int, $branchDivision: String!) {
  me {
    prescriptions(
      filter: $filter
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          created
          patientIdentifier
          referenceNumber
          isFreeDelivery
          patient {
            memberId
            firstName
            lastName
            modified
          }
          dispenseCount
          lastDispenseDate
          medications {
            id
            display
            refills
            dispenseCount
            instructions
            arabicInstructions
            duration
            quantity
            dispenseCount
            remainingDaysToNextRefill
            tradeDrugs(branchDivision: $branchDivision) {
              code
              dictionaryURL
              smallIconURL
            }
          }
          remainingDaysToNextRefill
          dispenseStatus
          earlyRefillRequests(first: $first, filter: $filterEarlyRefillRequests) {
            edges {
              node {
                id
                status
                reason {
                  reason
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __usePrescriptionsQuery__
 *
 * To run a query within a React component, call `usePrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      filterEarlyRefillRequests: // value for 'filterEarlyRefillRequests'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      branchDivision: // value for 'branchDivision'
 *   },
 * });
 */
export function usePrescriptionsQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionsQuery, PrescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrescriptionsQuery, PrescriptionsQueryVariables>(PrescriptionsDocument, options);
      }
export function usePrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionsQuery, PrescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrescriptionsQuery, PrescriptionsQueryVariables>(PrescriptionsDocument, options);
        }
export type PrescriptionsQueryHookResult = ReturnType<typeof usePrescriptionsQuery>;
export type PrescriptionsLazyQueryHookResult = ReturnType<typeof usePrescriptionsLazyQuery>;
export type PrescriptionsQueryResult = Apollo.QueryResult<PrescriptionsQuery, PrescriptionsQueryVariables>;