/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type BranchFragmentFragment = { __typename?: 'Branch', id: string, isActive: boolean, name: string, nameAr?: string | null, description: string, branchDivision?: number | null };

export const BranchFragmentFragmentDoc = gql`
    fragment BranchFragment on Branch {
  id
  isActive
  name
  nameAr
  description
  branchDivision
}
    `;