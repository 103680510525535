/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { BranchErrorsFragmentFragmentDoc } from '../../../../fragment/BranchesErrors/__generated__/BranchesErrorsFragment';
import { BranchFragmentFragmentDoc } from '../../../../fragment/branch/__generated__/branchFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchCreateMutationVariables = Types.Exact<{
  branch: Types.BranchInput;
  vendorId: Types.Scalars['ID'];
}>;


export type BranchCreateMutation = { __typename?: 'Mutation', branchCreate?: { __typename?: 'BranchCreate', branchErrors: Array<{ __typename?: 'BranchError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, branch?: { __typename?: 'Branch', id: string, isActive: boolean, name: string, nameAr?: string | null, description: string, branchDivision?: number | null } | null } | null };


export const BranchCreateDocument = gql`
    mutation branchCreate($branch: BranchInput!, $vendorId: ID!) {
  branchCreate(input: $branch, vendorId: $vendorId) {
    branchErrors {
      ...BranchErrorsFragment
    }
    branch {
      ...BranchFragment
    }
  }
}
    ${BranchErrorsFragmentFragmentDoc}
${BranchFragmentFragmentDoc}`;
export type BranchCreateMutationFn = Apollo.MutationFunction<BranchCreateMutation, BranchCreateMutationVariables>;

/**
 * __useBranchCreateMutation__
 *
 * To run a mutation, you first call `useBranchCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBranchCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [branchCreateMutation, { data, loading, error }] = useBranchCreateMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useBranchCreateMutation(baseOptions?: Apollo.MutationHookOptions<BranchCreateMutation, BranchCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BranchCreateMutation, BranchCreateMutationVariables>(BranchCreateDocument, options);
      }
export type BranchCreateMutationHookResult = ReturnType<typeof useBranchCreateMutation>;
export type BranchCreateMutationResult = Apollo.MutationResult<BranchCreateMutation>;
export type BranchCreateMutationOptions = Apollo.BaseMutationOptions<BranchCreateMutation, BranchCreateMutationVariables>;