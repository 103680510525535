import { useTranslation } from "@health/i18n";
import { formatMessageErrors, OrderError, useTicketPrescriptionDispenseMutation } from "@health/queries";
import { useAddToast } from "@health/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useTicketAddHook = () => {
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { t } = useTranslation("Admin");
  const [errors, setError] = useState<OrderError[]>([]);

  const [createTicket, { loading: isCreateLoading }] = useTicketPrescriptionDispenseMutation({
    onCompleted: data => {
      const errors = data.ticketPrescriptionDispense?.orderErrors;
      if (errors && errors?.length > 0) {
        const error = formatMessageErrors(errors);
        failed(t(error));
        setError(errors as OrderError[]);
      } else {
        succeeded(t("Customer Service Order Created Successfully"));
        navigate("/prescriptions");
      }
    },
    onError: error => {
      failed(t("Failed to Create Customer Service Order"));
      console.error(t("error"), error);
    },
  });

  const handleSubmit = data => {
    createTicket({
      variables: {
        input: {
          ...data,
          branchHealthLicenseNumbers: data?.branchHealthLicenseNumbers ? [data?.branchHealthLicenseNumbers] : [],
          csTicketId: data?.csTicketId ? data?.csTicketId : 0,
          shippingAddress: {
            ...(data?.shippingAddress || {}),
            coordinates: {
              lat: data?.shippingAddress?.coordinates?.lat ? data?.shippingAddress?.coordinates?.lat : 0,
              lng: data?.shippingAddress?.coordinates?.lng ? data?.shippingAddress?.coordinates?.lng : 0,
            },
          },
        },
      },
    });
  };

  return {
    errors,
    isCreateLoading,
    handleSubmit,
  };
};
