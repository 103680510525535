import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const ShopIcon: FC<SvgIconProps> = ({ width, height, viewBox, ...props }) => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      width={width ? width : "85.793"}
      height={height ? height : "95.019"}
      viewBox={viewBox ? viewBox : "0 0 85.793 95.019"}
      {...props}
    >
      <path
        id='icons8-shop-local'
        d='M45.775,0A19.366,19.366,0,0,0,26.384,19.392V23.27h-15.3l-.2,1.719-8,70.029H88.672l-8.2-71.749h-15.3V19.392A19.366,19.366,0,0,0,45.775,0Zm0,3.878A15.4,15.4,0,0,1,61.289,19.392V23.27H30.262V19.392A15.4,15.4,0,0,1,45.775,3.878ZM14.54,27.148H26.384v4.4a3.874,3.874,0,1,0,3.878,0v-4.4H61.289v4.4a3.874,3.874,0,1,0,3.878,0v-4.4H77.01l7.314,63.992H7.227ZM45.628,40.722c-8.532,0-15.513,7.37-15.513,16.29s15.513,30.25,15.513,30.25,15.513-21.33,15.513-30.25S54.16,40.722,45.628,40.722Zm0,3.878c6.4,0,11.635,5.624,11.635,12.411,0,4.654-6.205,15.513-11.635,23.463C40.2,72.525,33.993,61.666,33.993,57.012,33.993,50.225,39.228,44.6,45.628,44.6Zm.148,3.878a7.757,7.757,0,1,0,7.757,7.757A7.786,7.786,0,0,0,45.775,48.479Zm0,3.878A3.878,3.878,0,1,1,41.9,56.236,3.849,3.849,0,0,1,45.775,52.357Z'
        transform='translate(-2.879)'
        fill={props.fill ? props.fill : "#0000008a"}
      />
    </SvgIcon>
  );
};
