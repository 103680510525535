import { makeStyles } from "@health/ui";

export const useEntryContainerStyles = makeStyles()(() => ({
  form: {
    width: "100%",
  },
  content: {
    height: "100%",
    display: "flex",
    textAlign: "center",
    padding: "0px 44px 28px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    ["@media (max-width:700px)"]: {
      padding: "8px",
    },
  },
  singIn: {
    fontSize: 28,
    fontFamily: "CoHeadlineTrial-Light, Arab Kufi Medium",
  },
  footer: {
    height: 44,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  footerText: {
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));
