import { useReactiveVar } from "@apollo/client";
import { capitalize, UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { openVar } from "@health/queries";
import {
  AccountDropdownMenu,
  Box,
  CardMedia,
  CustomerIcon,
  IconButton,
  Layout,
  SidebarDataType,
  SmallAlert,
  ToastProvider,
  Typography,
} from "@health/ui";
import React, { useContext, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Notifications } from "shared/components";
import { useAppRoutes } from "./useAppRoutes";
import { routeWithSelectedItems } from "./utils";

export function LayoutComponent() {
  const navigate = useNavigate();
  const params = useParams();

  const { t, i18n } = useTranslation();
  const { isAuthenticated, user, logout } = useContext(UserContext);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const routes = useAppRoutes();

  const breadCrumb = useMemo(() => {
    const paramsObject: any = params;
    delete paramsObject?.["*"];
    const paramsArray = Object.values(paramsObject);
    const splittedPath = location.pathname.substring(1).split("/");
    return splittedPath
      .filter(item => !paramsArray.includes(item))
      .map((path, i) => {
        const indexVal = splittedPath?.[i + 1] && paramsArray.includes(splittedPath?.[i + 1]) ? i + 2 : i + 1;
        return {
          id: path,
          name: capitalize(path.split("-")),
          path: path,
          fullPath: "/" + splittedPath.slice(0, indexVal).join("/"),
          onClick: fullPath => {
            const i = splittedPath.indexOf(path);
            navigate(fullPath || "/" + splittedPath.slice(0, i + 1).join("/"));
          },
        };
      });
  }, [location, params]);

  const handleGoToHome = () => {
    navigate("/");
  };
  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    window.location.href = "/login";
    window?.["sseControllerRef"]?.abort();
    logout();
  };
  const handleClickClose = () => {
    setAnchorEl(null);
  };
  const open = useReactiveVar(openVar);

  const handleToggleDrawer = () => {
    openVar(!openVar());
  };

  return (
    <ToastProvider components={{ Toast: SmallAlert }} placement={i18n.dir(i18n.language) === "rtl" ? "top-left" : "top-right"}>
      {routes && (
        <Layout
          isOpen={open}
          title={t(breadCrumb[0]?.name)}
          breadCrumb={breadCrumb.slice(1, breadCrumb?.length)}
          drawerItems={routeWithSelectedItems(routes as unknown as SidebarDataType[])}
          onGoToHome={handleGoToHome}
          onToggleDrawer={handleToggleDrawer}
          rightItems={[
            {
              id: "notifications",
              icon: <Notifications />,
            },
            {
              id: "admin",
              icon: isAuthenticated && (
                <IconButton onClick={handleClickOpen} color={isOpen ? "info" : "primary"}>
                  <CustomerIcon />
                </IconButton>
              ),
            },
          ]}
          footer={
            <Box display='flex' flexDirection='column' alignItems='center' m='10px 0'>
              <CardMedia image='/assets/images/NUPCO.png' title='NUPCO' sx={{ width: "60px", height: "46px" }} />
              <Typography fontSize='14px'>Copyright © 2025 NUPCO</Typography>
            </Box>
          }
        >
          <Outlet />
        </Layout>
      )}

      {isOpen && (
        <AccountDropdownMenu
          username={[user?.firstName, user?.lastName].filter(Boolean).join(" ") || "User"}
          open={isOpen}
          onLogout={handleLogout}
          items={[]}
          anchorEl={anchorEl}
          onClose={handleClickClose}
        />
      )}
    </ToastProvider>
  );
}
