import { useApolloClient } from "@apollo/client";
import { setTokenToLocalStorage, UserContext } from "@health/common";
import { AppRoleTypes, AppTypes, TokenCreateMutation, useTokenCreateMutation } from "@health/queries";
import { openPharmacyBranch } from "@health/ui";
import { useContext, useState } from "react";

export const useLoginFormHooks = (app, afterSubmitSuccess?, onChangeStep?, onRouteView?) => {
  const { login } = useContext(UserContext);
  const [errors, setErrors] = useState([]);
  const client = useApolloClient();
  const [tokenCreate] = useTokenCreateMutation({
    onCompleted: (data: TokenCreateMutation) => {
      if (data?.tokenCreate?.accountErrors?.length > 0) {
        setErrors(data.tokenCreate?.accountErrors || []);
        return;
      }
      const { token, user, branches } = data?.tokenCreate!;
      if (!token && !user) {
        console.error("@Login token or user is null");
        return;
      }
      afterLogin(token, user, branches);
      app === AppTypes.Vendor && user?.appRole === AppRoleTypes.User && openPharmacyBranch(true);
    },
  });
  const afterLogin = (token, user, branches) => {
    login(token, user, branches);
    setTokenToLocalStorage(token);
    afterSubmitSuccess && afterSubmitSuccess();
    onChangeStep && onChangeStep();
    client.resetStore();
    onRouteView && onRouteView();
  };
  const onSubmit = data => {
    const userNameWithNoSpace = data.username.trim()?.replace(/ /g, "");

    let normalizedPhoneNumber = userNameWithNoSpace.trim();

    if (normalizedPhoneNumber.startsWith("00966")) {
      normalizedPhoneNumber = "+966" + normalizedPhoneNumber.slice(5);
    } else if (normalizedPhoneNumber.startsWith("9665")) {
      normalizedPhoneNumber = "+966" + normalizedPhoneNumber.slice(3);
    } else if (normalizedPhoneNumber.startsWith("05")) {
      normalizedPhoneNumber = "+966" + normalizedPhoneNumber.slice(1);
    } else if (normalizedPhoneNumber.startsWith("5")) {
      normalizedPhoneNumber = "+966" + normalizedPhoneNumber;
    }
    setErrors([]);
    tokenCreate({
      variables: {
        app: app,
        email: normalizedPhoneNumber,
        password: data.password,
      },
    });
  };
  return {
    errors,
    onSubmit,
  };
};
