import { useTranslation } from "@health/i18n";
import React, { FC } from "react";
import { EntryContainer } from "../../EntryContainer.component";
import { EntryLayout } from "../../EntryLayout.component";
import { LoginForm } from "../../Form/LoginForm";
import { LoginPageProps } from "./types";

export const LoginPage: FC<LoginPageProps> = ({ app, afterSubmitSuccess: handleSubmit, onForgetPassword: handleForgetPassword }) => {
  const { t } = useTranslation();
  return (
    <EntryLayout>
      <EntryContainer title={t("Yusur Signin")}>
        <LoginForm app={app} onChangeStep={handleSubmit} onForgetPassword={handleForgetPassword} />
      </EntryContainer>
    </EntryLayout>
  );
};
