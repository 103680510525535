import { bg, darkBlue, deepViolet, makeStyles, white } from "@health/ui";

export const useGoogleMapSearchWrapperStyles = makeStyles()(theme => {
  return {
    root: {
      width: "100%",
      height: "100vh",
    },
    mapContainerStyle: {
      height: "60vh",
      width: "100%",
    },
    peekToCurrentLocationButton: {
      borderRadius: "5px !important",
      "&:hover": {
        borderRadius: "5px !important",
      },
    },
    textField: {
      marginLeft: "1px",
      marginRight: "1px",
      width: 200,
    },
    container: {
      border: "1px solid #D3DDEA",
      borderRadius: 10,
      paddingLeft: 10,
      width: 250,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    saveButton: {
      color: "white",
      background: "#11AC5B",
      width: "100%",
      marginRight: "2px",
    },
    cancelButton: {
      color: "#003971",
      background: "#B1BDD1",
      width: "100%",
      marginRight: "2px",
    },
    deleteButton: {
      color: "white",
      background: "#FF1111",
      width: "100%",
      marginRight: "2px",
    },
    text: {
      width: "100%",
    },
    search: {
      flex: 1,
      height: "100%",
      flexDirection: "column",
      backgroundColor: "white",
    },
    searchIcon: {
      top: 0,
      position: "absolute",
      right: theme.direction === "rtl" ? undefined : 0,
      left: theme.direction === "rtl" ? 0 : undefined,
      marginInlineEnd: 0,
      marginBlock: 0,
      height: 50,
      color: white,
      backgroundColor: deepViolet,
      borderRadius: theme.direction === "ltr" ? "0 5px 5px 0" : "5px 0px 0px 5px",
      "&:hover": {
        backgroundColor: bg,
        color: darkBlue,
      },
    },
  };
});

export const useGoogleMapSearchStyles = makeStyles()({
  loadingElement: { height: `0` },
  containerElement: { height: `60px` },
  mapElement: { height: `0` },
});
