import { PaletteOptions } from "@mui/material/styles";
import { bordersGrey, burntOrange, darkBlue, darkGrey, darkRed, deepViolet, dustyRos, green, orange, white } from "../colors";

export const CustomThemePalette: PaletteOptions = {
  mode: "light",
  primary: {
    main: deepViolet,
  },
  secondary: {
    main: burntOrange,
  },
  success: {
    main: green,
  },
  warning: {
    main: orange,
  },
  error: {
    main: darkRed,
  },
  info: {
    main: dustyRos,
  },
  text: {
    primary: darkBlue,
  },
  darkGrey: {
    main: darkGrey,
  },
  divider: bordersGrey,
  background: {
    default: white,
    paper: white,
  },
};
