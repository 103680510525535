import { IconButton, styled } from "@mui/material";
import { deepViolet } from "../../customization";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${deepViolet}`,
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: deepViolet,
    color: "white",
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
  },
  margin: "auto 5px",
}));
